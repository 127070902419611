document.addEventListener("turbo:load", function () {
  const clipboardElements = document.querySelectorAll(".js-clipboard");

  if (!clipboardElements.length) {
    return;
  }

  clipboardElements.forEach(function (element) {
    element.addEventListener("click", function (evt) {
      evt.preventDefault();
      let href = element.getAttribute("href");

      if (!href) {
        console.warn("Element does not have a href attribute.");
        return;
      }

      if (href.startsWith("/")) {
        href = location.protocol + "//" + location.host + href;
      }

      navigator.clipboard.writeText(href).then(
          () => {
            const oldValue = element.textContent;
            element.textContent = "Lien copié!";
            setTimeout(() => {
              element.textContent = oldValue;
            }, 3000);
          },
          (err) => {
            console.error("Link failed to copy to clipboard", err);
          }
      );
      return false;
    });
  });
});

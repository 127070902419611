import { Turbo } from "@hotwired/turbo-rails";

document.addEventListener("turbo:load", () => {
  const multitagpackFilters = document.querySelectorAll(
    ".multitagpack-filter-selector",
  );
  if (!multitagpackFilters) {
    return;
  }
  const applyMultiPackTagFilter = (
    selectElement,
    type,
    otherType,
    otherValue,
  ) => {
    const newValue = parseInt(selectElement.value.split("-")[1], 10);
    document
      .querySelectorAll(".multitagpack-filter")
      .forEach(function (element) {
        if (!newValue && !otherValue) {
          element.style.display = "";
        } else if (!newValue) {
          element.dataset[`${otherType}-` + otherValue] === "true"
            ? (element.style.display = "")
            : (element.style.display = "none");
        } else if (!otherValue) {
          element.dataset[`${type}-` + newValue] === "true"
            ? (element.style.display = "")
            : (element.style.display = "none");
        } else if (
          element.dataset[`${type}-` + newValue] === "true" &&
          element.dataset[`${otherType}-` + otherValue] === "true"
        ) {
          element.style.display = "";
        } else {
          element.style.display = "none";
        }
      });
    return newValue;
  };

  multitagpackFilters.forEach((element) => {
    element.addEventListener("change", function (event) {
      const selectElement = event.target;
      const url = new URL(window.location.href);
      const type = selectElement.value.split("-")[0];
      const otherType = type === "tag" ? "pack" : "tag";
      const otherValue = parseInt(url.searchParams.get(`${otherType}`), 10);
      const newValue = applyMultiPackTagFilter(
        selectElement,
        type,
        otherType,
        otherValue,
      );
      url.searchParams.set(`${type}`, newValue);
      url.searchParams.set(`${otherType}`, otherValue);
      window.history.pushState({ path: url.href }, "", url.href);
    });

    const url = new URL(window.location.href);
    const type = element.value.split("-")[0];
    const otherType = type === "tag" ? "pack" : "tag";
    const otherValue = parseInt(url.searchParams.get(`${otherType}`), 10);
    applyMultiPackTagFilter(element, type, otherType, otherValue);
  });
});

import {FetchRequest} from '@rails/request.js'

document.addEventListener("turbo:load", function () {
  const formElements = document.querySelectorAll(".js-remote-form");
  if (!formElements.length) return;
  formElements.forEach(element => {
    element.addEventListener("turbo:submit-start", (event) => {
      event.detail.formSubmission.stop()

      const method = event.currentTarget.getAttribute("method") || "get"
      const action = event.currentTarget.getAttribute("action")
      const request = new FetchRequest(
          method,
          action,
          event.detail.formSubmission.body)
      request.perform().then(() => {
        console.log("form submitted")
      })
    })
  })
})

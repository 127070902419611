import {
  fbShare,
  linkedin,
  whatsapp,
  email
} from 'vanilla-sharing';

document.addEventListener("turbo:load", function () {
  const shareButtons = document.querySelectorAll('.js-share');

  if (shareButtons.length === 0) {
    return;
  }

  shareButtons.forEach(button => {
    button.addEventListener('click', function (event) {
      event.preventDefault();

      const url = this.getAttribute('data-url') || window.location.href;
      const title = this.getAttribute('data-title') || document.title;
      const description = this.getAttribute('data-description') || '';
      const emailTo = this.getAttribute('data-to') || '';
      const emailSubject = this.getAttribute('data-subject') || 'Check this out!';
      const emailBody = this.getAttribute('data-body') || `${title} - ${url}`;

      try {
        if (this.classList.contains('js-share-linkedin')) {
          linkedin({url, title, description});
        } else if (this.classList.contains('js-share-facebook')) {
          fbShare({url, quote: title});
        } else if (this.classList.contains('js-share-whatsapp')) {
          whatsapp({url, title});
        } else if (this.classList.contains('js-share-email')) {
          email({
            to: emailTo,
            url,
            title,
            description,
            subject: emailSubject,
            body: emailBody
          });
        } else {
          console.error('Sharing method not supported for this button.');
        }
      } catch (error) {
        console.error('Error during sharing:', error);
      }
    });
  });
});

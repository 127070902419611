import {Turbo} from "@hotwired/turbo-rails"

document.addEventListener("turbo:load", () => {
  const multitagFilters = document.getElementById("multitag-filter");
  if (!multitagFilters) {
    return;
  }
  const applyMultiTagFilter = (selectElement) => {
    const newValue = parseInt(selectElement.value, 10);
    document.querySelectorAll(".multitag-filter").forEach(function (element) {
      if (!newValue) {
        element.style.display = "";
      } else if (element.dataset["tag-" + newValue] === "true") {
        element.style.display = "";
      } else {
        element.style.display = "none";
      }
    });
    return newValue;
  }

  multitagFilters.addEventListener("change", function (event) {
    const selectElement = event.target;
    const newValue = applyMultiTagFilter(selectElement)
    const url = new URL(window.location.href);
    url.searchParams.set('tag', newValue);
    window.history.pushState({path: url.href}, '', url.href);
  });
  applyMultiTagFilter(multitagFilters)
});

// import "./cookie";
import "@rails/ujs"
import "@hotwired/turbo"
import * as ActiveStorage from "@rails/activestorage"
import LazyLoad from "vanilla-lazyload";
import "./client/service"
import "./cookie"
import "./client/share"
import "./client/indeterminate-checkbox"
import "./client/phone-mask"
import "./client/remote-form"
import "./client/multitag-filter"
import "./client/multitagpack-filter"
import "./client/todo-checkbox"
import "./client/clickable-row"
import "./client/admin-service"
import "./client/debounce-link"

document.addEventListener("turbo:load", function () {
  ActiveStorage.start()
  new LazyLoad({threshold: 600,});

  setTimeout(function () {
    const toasts = document.querySelectorAll(".toast")
    if (toasts) {
      toasts.forEach(function (element) {
        element.className += " hidden";
      });
    }
  }, 60 * 30 * 3);


});
